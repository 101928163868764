input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}

.App {
  text-align: center;
  background: rgb(0, 0, 0);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center top;
  background-attachment: fixed;
}
a {
  text-decoration: none;
}

/* styles wordcloud tooltip */
*[id^='tippy-'] {
  background-color: black!important;  
  color: white!important;
  padding: 3px!important;
}